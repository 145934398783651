import React, { useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from 'react-icons/md';
import styles from "../../Styles/ViewContentTable.module.css";

const ViewContentTable = ({ data, isLecture, onPageChange, currentPage, rowsPerPage, onView, onDelete,onEdit }) => {
  // State to handle filtering
  const [filters, setFilters] = useState({
    board: '',
    className: '',
    subject: '',
    chapter: ''
  });

  // Function to handle filter input changes
  const handleFilterChange = (column, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value,
    }));
  };

  // Filter data based on the selected filters
  const filteredData = data.filter((item) => {
    return (
      (!filters.board || item.board.toLowerCase().includes(filters.board.toLowerCase())) &&
      (!filters.className || item.className.toLowerCase().includes(filters.className.toLowerCase())) &&
      (!filters.subject || item.subject.toLowerCase().includes(filters.subject.toLowerCase())) 
    );
  });

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  // Total number of pages
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  // Manage visible range of pagination buttons
  const [startPage, setStartPage] = useState(1);
  const endPage = Math.min(startPage + 9, totalPages);

  // Handle previous page arrow click
  const handlePrevPage = () => {
    if (startPage > 1) {
      setStartPage(startPage - 1);
      onPageChange(startPage - 1);
    }
  };

  // Handle next page arrow click
  const handleNextPage = () => {
    if (endPage < totalPages) {
      setStartPage(startPage + 1);
      onPageChange(startPage + 1);
    }
  };

  return (
    <div className={styles.tableContainer}>
      <h3>View Content</h3>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>
              BOARD
              <input
                type="text"
                value={filters.board}
                onChange={(e) => handleFilterChange('board', e.target.value)}
                placeholder="Filter by board"
                className={styles.filterInput}
              />
            </th>
            <th>
              CLASS
              <input
                type="text"
                value={filters.className}
                onChange={(e) => handleFilterChange('className', e.target.value)}
                placeholder="Filter by class"
                className={styles.filterInput}
              />
            </th>
            <th>
              SUBJECT
              <input
                type="text"
                value={filters.subject}
                onChange={(e) => handleFilterChange('subject', e.target.value)}
                placeholder="Filter by subject"
                className={styles.filterInput}
              />
            </th>
            <th>
              CHAPTER
            </th>
            {!isLecture && <th>Test Type</th>}
            <th>VIEW</th>
            <th>DELETE</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.board}</td>
              <td>{item.className}</td>
              <td>{item.subject}</td>
              <td>{item.chapter}</td>
              {!isLecture && <td>{item.testType}</td>}
              
                <td>
                  <FaEye className={styles.icon} onClick={() => onView(item)} />
                </td>
             
              <td>
                <MdDelete className={styles.icon} onClick={() => onDelete(item)} />
              </td>
              <td>
                <FiEdit2 className={styles.icon} onClick={() => onEdit(item)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.pagination}>
        {/* Left Arrow */}
        {startPage > 1 && (
          <button onClick={handlePrevPage} className={styles.arrowButton}>
            &#60;
          </button>
        )}

        {/* Display pagination buttons */}
        {[...Array(endPage - startPage + 1)].map((_, index) => {
          const pageNum = startPage + index;
          return (
            <button
              key={pageNum}
              onClick={() => onPageChange(pageNum)}
              className={currentPage === pageNum ? styles.active : ''}
            >
              {pageNum}
            </button>
          );
        })}

        {/* Right Arrow */}
        {endPage < totalPages && (
          <button onClick={handleNextPage} className={styles.arrowButton}>
            &#62;
          </button>
        )}
      </div>
    </div>
  );
};

export default ViewContentTable;
