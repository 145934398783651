import React, { useState } from "react";
import { FaTimes, FaBook, FaChalkboardTeacher, FaQuestionCircle } from "react-icons/fa";
import styles from "../../Styles/TestEdit.module.css";
import { apiCallAdmin } from '../../utils/apiCallAdmin'; // Import the API call

const Modal = ({ show, onClose, testData }) => {
    const [editableData, setEditableData] = useState(testData || {});
    const [questionType, setQuestionType] = useState('mcq'); // Track the question type

    if (!show) return null;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditableData({
            ...editableData,
            [name]: value,
        });
    };

    const handleQuestionChange = (index, field, value, optionIndex = null) => {
        const updatedQuestions = editableData.binfile.question.map((question, idx) => {
            if (idx === index) {
                if (field === 'options') {
                    const updatedOptions = question.options.map((option, optIdx) =>
                        optIdx === optionIndex ? value : option
                    );
                    return { ...question, options: updatedOptions };
                } else {
                    return { ...question, [field]: value };
                }
            }
            return question;
        });
    
        setEditableData({
            ...editableData,
            binfile: {
                ...editableData.binfile,
                question: updatedQuestions,
            },
        });
    };
    
    const addNewQuestion = () => {
        let newQuestion = {};

        if (questionType === "mcq") {
            newQuestion = {
                question_number: editableData.binfile.question.length + 1, // Increment the question number
                question: "",
                exam_type: "mcq",
                options: ["", "", "", ""], // Empty options for MCQ
                answer: "",
                score: 0,
            };
        } else if (questionType === "short_question") {
            newQuestion = {
                question_number: editableData.binfile.question.length + 1, // Increment the question number
                question: "",
                exam_type: "short_question",
                answer: "",
                score: 0,
            };
        } else if (questionType === "long_answer") {
            newQuestion = {
                question_number: editableData.binfile.question.length + 1, // Increment the question number
                question: "",
                exam_type: "long_answer",
                answer: "",
                score: 0,
            };
        } else if (questionType === "fill_in_the_blanks") {
            newQuestion = {
                question_number: editableData.binfile.question.length + 1, // Increment the question number
                question: "",
                exam_type: "fill_in_the_blanks",
                answer: "",
                score: 0,
            };
        }

        setEditableData({
            ...editableData,
            binfile: {
                ...editableData.binfile,
                question: [...editableData.binfile.question, newQuestion],
            },
        });
    };

    // Handle Save button click
    function utf8ToBase64(str) {
        return btoa(unescape(encodeURIComponent(str)));
    }
    const handleSave = async () => {
        try {
            // Construct the data for the API call
            const dataToSave = editableData;
            console.log("DATA to Save >>>>", dataToSave)
            const jsonString = JSON.stringify(dataToSave);

            // Step 2: Convert the string to a Base64-encoded string
            const base64String = utf8ToBase64(jsonString);
            console.log("BASE 64 String", base64String)
            const payload = {
                "update": base64String
            }
            const response = await apiCallAdmin('test/edit', 'POST', payload); // Update the endpoint URL as needed
            if (response.success) {
                // Handle success (e.g., notify the user, close the modal)
                alert("Test data saved successfully!");
                onClose(); // Close the modal after saving
            } else {
                // Handle error (e.g., show an error message)
                alert("Failed to save test data.");
            }
        } catch (error) {
            console.error("Error saving test data:", error);
            alert("An error occurred while saving the test data.");
        }
    };

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <button className={styles.closeButton} onClick={onClose}>
                    <FaTimes />
                </button>
                <h3 className={styles.modalTitle}>Test Details</h3>
                {editableData && editableData.binfile && (
                    <div className={styles.testDetails}>
                        <div>
                            <label><FaBook /> <strong>Chapter Name:</strong></label>
                            <input
                                type="text"
                                name="chapter_name"
                                value={editableData.binfile.chapter_name || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label><FaBook /> <strong>Board:</strong></label>
                            <input
                                type="text"
                                name="board"
                                value={editableData.board || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label><FaBook /> <strong>Class:</strong></label>
                            <input
                                type="text"
                                name="className"
                                value={editableData.className || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label><FaChalkboardTeacher /> <strong>Subject:</strong></label>
                            <input
                                type="text"
                                name="subject"
                                value={editableData.subject || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label><FaBook /> <strong>Chapter:</strong></label>
                            <input
                                type="text"
                                name="chapter"
                                value={editableData.chapter || ''}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div>
                            <label><FaQuestionCircle /> <strong>Test Type:</strong></label>
                            <input
                                type="text"
                                name="testType"
                                value={editableData.testType || ''}
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className={styles.selectWrapper}>
                            <label className={styles.label}><strong>Question Type:</strong></label>
                            <select
                                className={styles.selectInput}
                                value={questionType}
                                onChange={(e) => setQuestionType(e.target.value)}
                            >
                                <option value="mcq">MCQ</option>
                                <option value="short_question">Short Question</option>
                                <option value="long_answer">Long Answer</option>
                                <option value="fill_in_the_blanks">Fill in the Blanks</option>
                            </select>
                        </div>

                        <div className={styles.questionsContainer}>
                            <h4>Questions</h4>
                            {editableData.binfile.question.map((question, index) => (
                                <div key={index} className={styles.questionCard}>
                                    <div>
                                        <label><strong>Q{question.question_number}:</strong></label>
                                        <textarea
                                            value={question.question || ''}
                                            onChange={(e) => handleQuestionChange(index, 'question', e.target.value)}
                                        />
                                    </div>

                                    {question.exam_type === 'mcq' && (
                                        <>
                                            <div>
                                                <label><strong>Answer:</strong></label>
                                                <input
                                                    type="text"
                                                    value={question.answer || ''}
                                                    onChange={(e) => handleQuestionChange(index, 'answer', e.target.value)}
                                                />
                                            </div>
                                            <div>
                                                <label><strong>Marks:</strong></label>
                                                <input
                                                    type="number"
                                                    value={question.score || ''}
                                                    onChange={(e) => handleQuestionChange(index, 'score', e.target.value)}
                                                />
                                            </div>
                                            <div>
                                                <label><strong>Options:</strong></label>
                                                <ul>
                                                    {question.options.map((option, idx) => (
                                                        <li key={idx}>
                                                            <input
                                                                type="text"
                                                                value={option}
                                                                onChange={(e) => handleQuestionChange(index, 'options', e.target.value, idx)} // Pass the index of the option
                                                            />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </>
                                    )}

                                    {question.exam_type === 'fill in the blanks' && (
                                        <>
                                            <div>
                                                <label><strong>Marks:</strong></label>
                                                <input
                                                    type="number"
                                                    value={question.score || ''}
                                                    onChange={(e) => handleQuestionChange(index, 'score', e.target.value)}
                                                />
                                            </div>
                                            <div>
                                                <label><strong>Answer:</strong></label>
                                                <input
                                                    type="text"
                                                    value={question.answer || ''}
                                                    onChange={(e) => handleQuestionChange(index, 'answer', e.target.value)}
                                                />
                                            </div>
                                        </>
                                    )}

                                    {question.exam_type === 'short_question' && (
                                        <>
                                            <div>
                                                <label><strong>Marks:</strong></label>
                                                <input
                                                    type="number"
                                                    value={question.score || ''}
                                                    onChange={(e) => handleQuestionChange(index, 'score', e.target.value)}
                                                />
                                            </div>
                                            <div>
                                                <label><strong>Answer:</strong></label>
                                                <input
                                                    type="text"
                                                    value={question.answer || ''}
                                                    onChange={(e) => handleQuestionChange(index, 'answer', e.target.value)}
                                                />
                                            </div>
                                        </>
                                    )}

                                    {question.exam_type === 'long_answer' && (
                                        <>
                                            <div>
                                                <label><strong>Marks:</strong></label>
                                                <input
                                                    type="number"
                                                    value={question.score || ''}
                                                    onChange={(e) => handleQuestionChange(index, 'score', e.target.value)}
                                                />
                                            </div>
                                            <div>
                                                <label><strong>Answer:</strong></label>
                                                <textarea
                                                    value={question.answer || ''}
                                                    onChange={(e) => handleQuestionChange(index, 'answer', e.target.value)}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div style={{ display: 'flex', gap: '10px' }}>
                    <button
                        style={{
                            width: '50%',
                            borderRadius: '6px',
                            padding: '10px',
                            border: 'none',
                            color: 'white',
                            background: '#33dcc8b4',
                            cursor: 'pointer',
                            margin: '8px auto',
                            maxWidth: '200px'
                        }}
                        onClick={addNewQuestion} // Add the new question
                    >
                        Add
                    </button>
                    <button
                        style={{
                            width: '50%',
                            borderRadius: '6px',
                            padding: '10px',
                            border: 'none',
                            color: 'white',
                            background: '#33dcc8b4',
                            cursor: 'pointer',
                            margin: '8px auto',
                            maxWidth: '200px'
                        }}
                        onClick={handleSave} // Save the test data
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
