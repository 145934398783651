import React, { useState } from "react";
import { FaTimes, FaBook, FaChalkboardTeacher, FaLink } from "react-icons/fa";
import styles from "../../Styles/LectureEditModal.module.css";
import { apiCallAdmin } from "../../utils/apiCallAdmin"; // Import the API call

const LectureEditModal = ({ show, onClose, lectureData }) => {
    const [editableData, setEditableData] = useState(lectureData || {});
    console.log("LECTURE DATA", lectureData)
    if (!show) return null;

    const handleInputChange = (e, chapterIndex, lectureIndex) => {
        const { name, value } = e.target;
      
        // Update the specific lecture in the chapters array
        const updatedChapters = editableData.binfile.chapters.map((chapter, cIndex) => {
          if (cIndex === chapterIndex) {
            const updatedLectures = chapter.lecture.map((lecture, lIndex) => {
              if (lIndex === lectureIndex) {
                return {
                  ...lecture,
                  [name]: value,
                };
              }
              return lecture;
            });
      
            return {
              ...chapter,
              lecture: updatedLectures,
            };
          }
          return chapter;
        });
      
        setEditableData({
          ...editableData,
          binfile: {
            ...editableData.binfile,
            chapters: updatedChapters,
          },
        });
      };
      
      const addNewLecture = () => {
        // Define a new lecture with default values
        const newLecture = {
            title: "",
            url: "",
            channel: "",
            views: 0,
        };
    
        // Add the new lecture to the first chapter for simplicity
        const updatedChapters = [...editableData.binfile.chapters];
    
        updatedChapters[0].lecture.push(newLecture);
    
        setEditableData({
            ...editableData,
            binfile: {
                ...editableData.binfile,
                chapters: updatedChapters,
            },
        });
    };
    
      
    const handleSave = async () => {
        try {
            const dataToSave = editableData;
            const jsonString = JSON.stringify(dataToSave);
            const base64String = btoa(unescape(encodeURIComponent(jsonString)));
            const payload = {
                update: base64String,
            };
            console.log("BASE^$",payload)
            const response = await apiCallAdmin('lecture/update', "POST", payload);

            if (response.success) {
                alert("Lecture data saved successfully!");
                onClose();
            } else {
                alert("Failed to save lecture data.");
            }
        } catch (error) {
            console.error("Error saving lecture data:", error);
            alert("An error occurred while saving the lecture data.");
        }
    };

    return (
        <div className={styles.modalOverlay}>
        <button className={styles.closeButton} onClick={onClose}>
                    <FaTimes />
                </button>
            <div className={styles.modalContent}>
                
                <h3 className={styles.modalTitle}>Lecture Details</h3>
                {editableData && editableData.binfile?.chapters && editableData.binfile.chapters.map((chapter, chapterIndex) => (
                    <div key={chapterIndex} className={styles.chapterCard}>
                        <h4>{chapter.chapter_name} ({chapter.chapter_number})</h4>

                        {chapter.lecture && chapter.lecture.map((lecture, lectureIndex) => (
                            <div key={lectureIndex} className={styles.lectureCard}>
                                <div>
                                    <label>
                                        <FaBook /> <strong>Title:</strong>
                                    </label>
                                    <input
                                        type="text"
                                        name="title"
                                        value={lecture.title || ""}
                                        onChange={(e) => handleInputChange(e, chapterIndex, lectureIndex)} // pass chapterIndex and lectureIndex
                                    />
                                </div>
                                <div>
                                    <label>
                                        <FaLink /> <strong>URL:</strong>
                                    </label>
                                    <input
                                        type="text"
                                        name="url"
                                        value={lecture.url || ""}
                                        onChange={(e) => handleInputChange(e, chapterIndex, lectureIndex)} // pass chapterIndex and lectureIndex
                                    />
                                </div>
                                <div>
                                    <label>
                                        <FaChalkboardTeacher /> <strong>Channel:</strong>
                                    </label>
                                    <input
                                        type="text"
                                        name="channel"
                                        value={lecture.channel || ""}
                                        onChange={(e) => handleInputChange(e, chapterIndex, lectureIndex)} // pass chapterIndex and lectureIndex
                                    />
                                </div>
                                <div>
                                    <label>
                                        <strong>Views:</strong>
                                    </label>
                                    <input
                                        type="number"
                                        name="views"
                                        value={lecture.views || 0}
                                        onChange={(e) => handleInputChange(e, chapterIndex, lectureIndex)} // pass chapterIndex and lectureIndex
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
                <div style={{ display: "flex", gap: "10px" }}>
                    <button
                        style={{
                            width: "50%",
                            borderRadius: "6px",
                            padding: "10px",
                            border: "none",
                            color: "white",
                            background: "#33dcc8b4",
                            cursor: "pointer",
                            margin: "8px auto",
                            maxWidth: "200px",
                        }}
                        onClick={addNewLecture} 
                    >
                        Add Lecture
                    </button>
                    <button
                        style={{
                            width: "50%",
                            borderRadius: "6px",
                            padding: "10px",
                            border: "none",
                            color: "white",
                            background: "#33dcc8b4",
                            cursor: "pointer",
                            margin: "8px auto",
                            maxWidth: "200px",
                        }}
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LectureEditModal;
