import React, { useState, useEffect } from 'react';
import AdminLayout from '../../Layouts/AdminLayout';
import { Assesment } from "../../components/Dropdowns/index";
import { apiCallAdmin } from '../../utils/apiCallAdmin';
import ViewContentTable from '../../components/Admin/ViewConatentTable';
import LectureViewModal from '../../components/Admin/LectureViewModal';
import LectureEditModal from '../../components/Admin/LectureEditModal';
import TestEditModal from '../../components/Admin/TestEditModal'
import TestViewModal from '../../components/Admin/TestViewModal';
import styles from "../../Styles/CourseDetails.module.css";

function CourseDetails() {
  const [selected_fileType, setSelectedfiletype] = useState("");
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLectureModalOpen, setIsLectureModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLectureEditModalOpen, setIsLectureEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  const fetchData = async (contentType) => {
    try {
      const response = await apiCallAdmin('getContent', 'POST', { contentType });
      if (response.success) {
        setData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleViewCourse = () => {
    if (selected_fileType === 'Lecture' || selected_fileType === 'Test') {
      fetchData(selected_fileType);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleViewItem = (item) => {
    if (selected_fileType === 'Lecture') {
      setModalData(item);
      setIsLectureModalOpen(true);
    } else {
      setModalData(item);
      setIsModalOpen(true);
    }
  };
  const handleSaveLectureChanges = () => {
    console.log("HEllo OWrld ");
  }
  const handleEditItem = (item) => {
    if (selected_fileType === 'Lecture') {
      setModalData(item); // Pass the selected item (lecture data) to the modal
      setIsLectureEditModalOpen(true); // Open the edit modal for lectures
    } else {
      setModalData(item); // Pass test data if editing a test
      setIsEditModalOpen(true); // Open the edit modal for tests
    }
  };
  
  const handleDeleteItem = async (item) => {
    try {
      const response = await apiCallAdmin('deleteContent', 'POST', {
        id: item.id,
        contentType: selected_fileType
      });

      if (response.success) {
        setData((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
      } else {
        console.error("Error deleting item:", response.message);
      }
    } catch (error) {
      console.error("Error in handleDeleteItem:", error);
    }
  };

  return (
    <AdminLayout>
      <div className={styles.container}>
        <div className={styles.drop_container}>
          <Assesment value={selected_fileType} onChange={(e) => setSelectedfiletype(e.target.value)} />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <button className={styles.view} onClick={handleViewCourse}>View</button>
      </div>

      <ViewContentTable
        data={data}
        currentPage={currentPage}
        rowsPerPage={rowsPerPage}
        isLecture={selected_fileType === 'Lecture'}
        onPageChange={handlePageChange}
        onView={handleViewItem}
        onEdit={handleEditItem}
        onDelete={handleDeleteItem}
      />

      {isModalOpen && (
        <TestViewModal
          show={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          testData={modalData}
        />
      )}

      {isLectureModalOpen && (
        <LectureViewModal
          show={isLectureModalOpen}
          onClose={() => setIsLectureModalOpen(false)}
          lectureData={modalData}
        />
      )}
      {isEditModalOpen && (
        <TestEditModal
          show={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          testData={modalData} // Pass the test data for editing
        />
      )}
      {isLectureEditModalOpen && (
        <LectureEditModal
    show={isLectureEditModalOpen}
    onClose={() => setIsLectureEditModalOpen(false)}
    lectureData={modalData} // Ensure modalData is the lecture data you want to edit
  />
      )}
    </AdminLayout>
  );
}

export default CourseDetails;